import React from 'react'

export default function Portal() {
  return (
    <div>Portal
        
    </div>

  )
}
